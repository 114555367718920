<template>
  <div class="">
    <div class="flex background between" style="height: calc(100vh - 275px)">
      <div class="left-section scroll-verticle">
        <div class="instruction-title">
          <div style="">{{ testDetails.Title }}</div>
        </div>
        <div class="pd-left45 pd-right45" style="margin-top: 20px">
          <div class="flex between" style="border-bottom: 1px solid #e0e4f0">
            <span class="fw-700" style="font-size: 15px; color: #3751ff">Test Instructions</span>
            <div @click="enableTestEditor()" style="cursor: pointer; color: #3751ff">
              <i class="material-icons" style="font-size: 15px; margin-right: 5px">edit</i>
            </div>
          </div>
          <div>
            <p
              style="font-size: 13px; font-weight: 400; white-space: break-spaces"
              v-html="this.testInstruction"
            ></p>
          </div>
        </div>
        <div class="scroll-verticle pd-left45 pd-right45">
          <div
            class=""
            style="margin-top: 20px; border-bottom: 1px solid #e0e4f0; padding-bottom: 20px"
          >
            <div class="fs-13 fw-700">
              Course:
              <span class="m-left10 fs-13 fw-400" style="color: #676767">{{
                testDetails.CourseName
              }}</span>
            </div>
            <div class="grid-class">
              <span class="fs-13 fw-700"
                >Total Questions:
                <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                  testDetails.QuestionCount
                }}</span></span
              >
              <span class="fs-13 fw-700"
                >Exam Duration:
                <span class="fs-13 fw-400 m-left10" style="color: #676767; padding-bottom: 8px">{{
                  testDetails.Duration
                }}</span></span
              >
              <span class="fs-13 fw-700" style="padding-top: 8px"
                >Total Sections:
                <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                  testSectionDetails.length
                }}</span></span
              >
              <div class="fs-13 fw-700 flex" style="padding-top: 8px; padding-right: 5px">
                Total Marks:
                <div class="number-button">{{ testDetails.TotalMarks }}</div>
              </div>
            </div>
          </div>
          <div v-for="(data, index) in testSectionDetails" :key="index" class="summary-detail">
            <div>
              <span class="fw-700" style="font-size: 15px; color: #3751ff; padding-top: 20px">{{
                data.SectionName
              }}</span>
              <div class="grid-class" style="margin-bottom: 20px; margin-top: 15px">
                <div class="">
                  <div class="fs-13 fw-700">
                    Total Marks:
                    <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                      data.MaxMarks
                    }}</span>
                  </div>
                  <div class="fs-13 fw-700">
                    Total Questions:
                    <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                      data.NoOfQuestions
                    }}</span>
                  </div>
                </div>
                <div class="">
                  <div class="fs-13 fw-700">
                    Max. Attempts:
                    <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                      data.AllowedAttempts
                    }}</span>
                  </div>
                  <div class="fs-13 fw-700">
                    Question Type:
                    <span class="fs-13 fw-400 m-left10" style="color: #676767">{{
                      data.QuestionType
                    }}</span>
                  </div>
                </div>
              </div>
              <span class="fw-700 fs-13">Marking Scheme:</span>
              <div class="flex" style="color: #676767">
                <div class="fs-13 fw-700" style="padding-right: 33px">
                  Marks per Question: <span class="fw-400">{{ data.MarksPerQuestion }}</span>
                </div>
                <div class="fs-13 fw-700">
                  Negative Marks: <span class="fw-400">{{ data.NegativeMarks }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-section" style="margin-right: 75px">
        <img :src="require(`../assets/preview.svg`)" alt="" />
      </div>
    </div>
    <Modal2
      :show="editInstructionModal"
      @close="editInstructionModal = false"
      :showHeader="false"
      :width="'70%'"
    >
      <template v-slot:body class="">
        <vue-editor v-model="testInstruction" style="margin-top: 20px"></vue-editor>
        <button class="modal-save-button" @click="saveEditText()" style="">Save</button>
      </template>
    </Modal2>
  </div>
</template>

<script>
import moment from "moment";
import VueEditor from "vue3-editor";
import Modal2 from "./Model2.vue";
import MobileApi from "../Mobileapi";

export default {
  data() {
    return {
      testSummary: [],
      testDetails: [],
      testSubjectDetails: [],
      testSectionDetails: [],
      testInstruction: "",
      editInstructionModal: false,
      isInstructionUpdated: false,
      SummaryData: [],
    };
  },
  props: {
    // testSummaryData: String,
    instituteTestId: Number,
  },
  components: { Modal2, VueEditor },
  created() {
    this.testSummary = JSON.parse(sessionStorage.getItem("summaryData"));
    if (this.testSummaryData !== null || this.testSummaryData !== undefined) {
      this.testDetails = this.testSummary.testDetails[0];
      this.testSubjectDetails = this.testSummary.subjectChapterDetails;
      this.testSectionDetails = this.testSummary.testSectionDetails;
      this.testInstruction = this.testDetails.TestInstruction;
      if (!this.testInstruction) {
        this.testInstruction =
          "1. Read the questions carefully before submitting the answer.<br> 2.The clock has been set at the serer and the countdown timer at the top of your screen will display the time remaining for you to complete the test. <br> 3. When the clock runs out, the test ends by default. Check if there are negative marking. <br> 4. No marks will be awarded/deducted if the question is not attempted.";
      }
      const data = {
        instituteTestId: this.instituteTestId,
        testInstruction: this.testInstruction,
      };
      MobileApi.updateCreateTestInstructions(data, (resp) => {
        this.update = resp.data;
      });
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
      }
      return value;
    },
    enableTestEditor() {
      this.editInstructionModal = !this.editInstructionModal;
    },
    saveEditText() {
      this.isInstructionUpdated = true;
      const data = {
        // instituteTestId: 40103,
        instituteTestId: this.instituteTestId,
        testInstruction: this.testInstruction,
      };
      this.$store.dispatch("showLoader", true);
      MobileApi.updateCreateTestInstructions(data, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.responseCode === 200) {
          this.$store.dispatch("showLoader", true);
          MobileApi.getInstituteTestSummary(
            {
              instituteTestId: this.instituteTestId,
            },
            (resp) => {
              if (resp.data) {
                sessionStorage.setItem("summaryData", JSON.stringify(resp.data));
                this.editInstructionModal = false;
                this.$store.dispatch("showLoader", false);
              }
              this.$store.dispatch("showLoader", false);
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex !important;
}
.background {
  background: #f3f5fc !important;
}
.p20 {
  padding: 20px !important;
}
.pd-left45 {
  padding-left: 45px !important;
}
.pd-right45 {
  padding-right: 45px !important;
}
.m-left10 {
  margin-left: 10px !important;
}
.between {
  justify-content: space-between !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.grid-class {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  margin-top: 8px;
}
.left-section {
  width: 652px;
  max-height: calc(100vh - 270px) !important;
  height: 100%;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  background: #ffffff;
  padding: 5px;
}
.instruction-title {
  background-color: #f3f5fc;
  padding-left: 40px;
  padding: 9px;
  font-size: 15px;
  font-weight: 700;
}
.number-button {
  height: 20px;
  color: #3751ff;
  background: #f3f5fc;
  font-size: 13px;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  text-align: center;
  margin-left: 10px;
}
.modal-save-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: right;
  width: 100px;
  background: #37841c;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.select-box.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.summary-detail {
  margin-top: 20px;
  border-bottom: 1px solid #e0e4f0;
  white-space: break-spaces;
  padding-bottom: 20px;
}
.summary-detail:last-child {
  border-bottom: none;
}
</style>
